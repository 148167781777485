<template>
  <div id="section5">
    <div class="fondoSilver">
      <b-row v-responsive.md.lg.xl align-v="center" style="margin: 0">
        <b-col md="4" class="text-right" >
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <h4 style="margin-left: 3em">
            {{$t("payText")}}
          </h4>
          <br>
          <div>
            <a @click="menuClicked(payUrl)" class="btn buttonTurqueza" >
              {{$t("payBtn")}}
            </a>
          </div>
          <br>
        </b-col>
        <b-col md="8" style="padding: 0">
          <b-img :src="payImg" class="imgContS5"/>
        </b-col>
      </b-row>
      <b-container fluid v-responsive.sm.xs>
        <b-container>  
          <br>        
          <b-row>
            <b-col sm="12" class="text-center">
              <br>
              <h2>
                {{$t("payTitulo")}}
              </h2>
              <p>
                {{$t("payText")}}
              </p>
              <br>
              <div>
                <a @click="menuClicked(payUrl)" class="btn buttonTurqueza" >
                  {{$t("payBtn")}}
                </a>
              </div>
              <br>
            </b-col>
            <b-col sm="12">
              <div>
                <b-img :src="payImg" class="imgContS5"/>
              </div>
              <br>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "DCC service",
    "payText": "Facilitate the purchases of your foreign customers by accepting payments in the currency of their country of origin. This way they will know the exact amount that will be charged.",
    "payBtn": "MORE INFORMATION"
  },
  "es":{
    "payTitulo": "Servicio DCC.",
    "payText": "Facilita las compras de tus clientes extranjeros aceptando pagos en la divisa de su país de origen. Así sabrán el importe exacto que se les cargará.",
    "payBtn": "MÁS INFORMACIÓN"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payUrl: "page_pay2.1",
      payImg: require("@/assets/images/pay/dcc.jpg")
    }
  },
  methods: {
    menuClicked(itemName) {
      itemName += '_'+this.$i18n.locale;
      if (itemName.includes("page_pay")) this.$router.push({ name: itemName });
    }
  }
};
</script>