<template>
  <div class="solucion1">
    <div class="wrapper">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from "@/components/pay/solucion/page1/Section1";
import Section2 from "@/components/pay/solucion/page1/Section2";
import Section3 from "@/components/pay/solucion/page1/Section3";
import Section4 from "@/components/pay/solucion/page1/Section4";
import Section5 from "@/components/pay/solucion/page1/Section5";
import Section6 from "@/components/pay/solucion/page1/Section6";

export default {
  name: "solucion1",
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
  },
  head: {
    title: {
      inner: "PAGOS EN TIENDAS"
    },
  },
  mounted() {
    this.$i18n.locale = "es";
  }
};
</script>