<template>
  <div id="section6">
    <div class="fondoGrisClaro" >
      <b-container fluid>
        <b-container class="text-center">
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <p>
            {{$t("payText")}}
          </p>
          <br>
            <b-row align-h="center">
              <b-col md="4" sm="12" class="text-center"
                v-for="(item, idx) in payList" :key="idx">
                <div class="text-center">
                  <a
                    class="btn paybtnIcon"
                  >
                    <font-awesome-icon :icon="payListI[idx].btnImg" class="socialIconPay" />
                  </a>
                  <br>
                  <h3>
                    {{item.titulo}}
                  </h3>
                  <p>
                    {{item.texto}}
                  </p>
                </div>
                <br>
              </b-col>
            </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Other services.",
    "payText": "Make your customers' lives a little easier with these services that we offer in all our terminals.",     
    "payList": [
      {
        "titulo": "Contactless"
        , "texto": "Accepts payments just bringing the card or smartphone closer to the POS."
      },
      {
        "titulo": "Tax free"
        , "texto": "Your customers outside EU recover VAT for purchases equal to or greater than € 90.15."
      },
      {
        "titulo": "Tips"
        , "texto": "Perfect for taxis, hotels or restaurants to facilitate the collection of tips."
      }
    ]
  },
  "es":{
    "payTitulo": "Otros servicios.",
    "payText": "Haz la vida de tus clientes un poco más fácil con estos servicios que ofrecemos en todos nuestros terminales.",     
    "payList": [
      {
        "titulo": "Contactless"
        , "texto": "Admite pagos tan sólo acercando la tarjeta o smartphone al TPV."
      },
      {
        "titulo": "Tax free"
        , "texto": "Tus clientes de fuera de la UE recuperan el IVA por compras iguales o superiores a 90,15 €."
      },
      {
        "titulo": "Propinas"
        , "texto": "Perfecto para que taxis, hoteles o restaurantes faciliten el cobro de propinas."
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payListI:[
        {
          btnImg: ['fas','wifi']
        },
        {
          btnImg: ['fas','shopping-cart']
        },
        {
          btnImg: ['fas','money-bill-alt']
        }
      ]
    };
  },
  computed: {
    payList() {
      return this.$t("payList");
    }
  }
};
</script>