<template>
  <div id="section1">
    <div class="fondoSilver">
      <b-img :src="payImg" class="imgContS5" style="height: 30vh"/>
      <b-container fluid>
        <b-container>
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <p class="text-justify">
            {{$t("payText")}}
          </p>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Store POS payments: Prepare your business to welcome any card.",
    "payText": "All our physical terminals are adapted to the latest technologies and ready to accept credit and debit cards from all over the world."
  },
  "es":{
    "payTitulo": "Pagos con TPV tienda: Prepara tu negocio para dar la bienvenida a cualquier tarjeta.",
    "payText": "Todos nuestros terminales físicos están adaptados a las últimas tecnologías y preparados para aceptar tarjetas de crédito y débito de todo el mundo."
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payImg: require("@/assets/images/pay/img_header_pagosentienda.jpg")
    }
  }
};
</script>
