<template>
  <div id="section3">
    <div class="fondoSilver">
      <b-container fluid>
        <b-container>
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <p class="text-justify">
            {{$t("payText")}}
          </p>
          <b-row>
            <b-col md="3" sm="12" v-for="(item, idx) in payList" :key="idx" @mouseleave="bounceFocusOut()">
              <div class="divContS3">
                <div class="divImgContS3" >
                  <b-img :src="payListI[idx].img" class="imgContS3" @mouseover="bounceFocusIn(this, idx)"/>
                </div>
                <transition-group name="bounce" :key="'t'+idx">
                <div class="divSubContS3" v-if="showTxt[idx]" :key="'txt'+idx" @mouseover="bounceFocusIn(this, idx)">
                  {{item.txt}}
                </div>
                <div class="divBtnContS3" v-show="showBtn[idx]" :key="'btn'+idx" @mouseover="bounceFocusIn(this, idx)">
                  <a :href="item.doc" class="btn buttonTurqueza" @mouseover="bounceFocusIn(this, idx)"
                    style="font-size: 0.8em !important">
                    <div class="">
                      {{item.descarga1}}
                    </div>
                    <div>
                      <span>{{item.descarga2}}&nbsp;</span>
                      <span>
                        <font-awesome-icon :icon="payListI[idx].icon" size="sm" />
                      </span>
                    </div>
                  </a>
                </div>
                </transition-group>
              </div>
              <br>
            </b-col>
          </b-row>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Meet our store terminals.",
    "payText": "We put at your disposal a great variety of POS. Choose the one that best suits your business needs and manage the card payments of  your customers.",
    "payList": [
      {
        "txt": "FIXED TERMINALS"
        , "descarga1": "DOWNLOAD"
        , "descarga2": "SPECIFICATIONS"
        , "doc": "#"
      },
      {
        "txt": "WIRELESS TERMINALS"
        , "descarga1": "DOWNLOAD"
        , "descarga2": "SPECIFICATIONS"
        , "doc": "#"
      },
      {
        "txt": "MOBILE TERMINALS"
        , "descarga1": "DOWNLOAD"
        , "descarga2": "SPECIFICATIONS"
        , "doc": "#"
      },
      {
        "txt": "TPV PC TERMINALS"
        , "descarga1": "DOWNLOAD"
        , "descarga2": "SPECIFICATIONS"
        , "doc": "#"
      }
    ]  
  },
  "es":{
    "payTitulo": "Conoce nuestros terminales de tienda.",
    "payText": "Ponemos a tu disposición una gran variedad de TPVs. Elige el que mejor se adapte a las necesidades de tu negocio y gestiona los pagos con tarjeta de tus clientes.",
    "payList": [
      {
        "txt": "TERMINALES FIJOS"
        , "descarga1": "DESCARGAR"
        , "descarga2": "ESPECIFICACIONES"
        , "doc": "#"
      },
      {
        "txt": "TERMINALES INALÁMBRICOS"
        , "descarga1": "DESCARGAR"
        , "descarga2": "ESPECIFICACIONES"
        , "doc": "#"
      },
      {
        "txt": "TERMINALES MÓVILES"
        , "descarga1": "DESCARGAR"
        , "descarga2": "ESPECIFICACIONES"
        , "doc": "#"
      },
      {
        "txt": "SOLUCIÓN TPV PC"
        , "descarga1": "DESCARGAR"
        , "descarga2": "ESPECIFICACIONES"
        , "doc": "#"
      }
    ]  
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      showTxt: [true,true,true,true],
      showBtn: [false,false,false,false],
      payListI: [
        {
          img: require("@/assets/images/pay/img_pagosentienda_01.jpg")
          , icon: ['fas','download']
        },
        {
          img: require("@/assets/images/pay/img_pagosentienda_05.jpg")
          , icon: ['fas','download']
        },
        {
          img: require("@/assets/images/pay/img_pagosentienda_03.jpg")
          , icon: ['fas','download']
        },
        {
          img: require("@/assets/images/pay/img_pagosentienda_04.jpg")
          , icon: ['fas','download']
        }
      ]
    }
  },
  methods: {
    bounceFocusIn: function (event, idx) {
      this.resetFocusArray();
      this.showTxt.splice(idx,1,false);
      this.showBtn.splice(idx,1,true);
    },
    bounceFocusOut: function () {
      this.resetFocusArray();
    },
    resetFocusArray: function(){
      for (let index = 0; index < this.showTxt.length; index++) {
         this.showTxt.splice(index,1,true);
      }
      for (let index = 0; index < this.showBtn.length; index++) {
         this.showBtn.splice(index,1,false);
      }
    }
  },
  computed: {
    payList() {
      return this.$t("payList");
    }
  }
};
</script>
