<template>
  <div id="section2">
    <div class="fondoWhite">
      <b-container fluid>
        <b-container>
          <br>
          <h2>
            {{$t("payTitulo")}}
          </h2>
          <p class="text-justify">
            {{$t("payText")}}
          </p>
          <br>
          <b-row align-h="center" align-v="center">
            <b-col md="2" sm="6" v-for="(item, idx) in payList" :key="idx">
              <div class="text-center">
                <strong>{{item.title}}</strong>
                <br>
                <b-img :src="item.img" />
              </div>
            </b-col>
          </b-row>
          <br>
          <p class="text-justify">
            {{$t("payText2")}}
          </p>
          <br>
          <b-row align-h="center" align-v="center">
            <b-col md="2" sm="6" v-for="(item, idx) in payList2" :key="idx">
              <div class="text-center">
                <strong>{{item.title}}</strong>
                <br>
                <b-img :src="item.img" />
              </div>
            </b-col>
          </b-row>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "Payment methods accepted.",
    "payText": "Allow your customers to make payments quickly and easily with a wide range of cards.",
    "payText2": "We also accept:"
  },
  "es":{
    "payTitulo": "Medios de pago aceptados.",
    "payText": "Permite a tus clientes realizar pagos en tu comercio de forma rápida y sencilla con un amplísimo abanico de tarjetas.",
    "payText2": "Además enrutamos:"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payList: [
        {
          title: "VISA"
          , img: require("@/assets/images/pay/visa.png")
        },
        {
          title: "MASTERCARD"
          , img: require("@/assets/images/pay/mastercard.png")
        },
        {
          title: "UNIONPAY"
          , img: require("@/assets/images/pay/unionpay.png")
        },
        {
          title: "MAESTRO"
          , img: require("@/assets/images/pay/maestro.png")
        },
        {
          title: "DISCOVER"
          , img: require("@/assets/images/pay/discover.png")
        }
      ],
      payList2: [
        {
          title: "AMERICAN EXPRESS"
          , img: require("@/assets/images/pay/american_express.png")
        }
      ]
    }
  }
};
</script>
